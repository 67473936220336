import styled from "styled-components";
import { rem } from "polished";

export const WrapperFooter = styled.footer`
`;

export const ImgIlustracion = styled.img`
  position: absolute;
  right: 0;
  width: 30vw;
   
  @media (min-width: 320px) { height: ${rem('71px')}; }

  @media (min-width: 400px) { height: ${rem('89px')}; }

  @media (min-width: 550px) { height: ${rem('122px')}; }

  @media (min-width: 750px) { height: ${rem('167px')}; }

  @media (min-width: 1000px) { height: ${rem('222px')}; }

  @media (min-width: 1200px) { height: ${rem('267px')}; }

  @media (min-width: 1440px) { height: ${rem('320px')}; }

  @media (min-width: 1920px) { height: ${rem('423px')}; }

`;