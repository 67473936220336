import * as React from 'react';
import { IfooterProps } from './IfooterProps';

import { WrapperFooter, ImgIlustracion } from "./footer.style";

import svg from "../resources/ilustracion-landing.svg";

const footer: React.SFC<IfooterProps> = (props) => {
  return (
      <WrapperFooter>
        <ImgIlustracion src={svg} />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0077ff" fillOpacity="1" d="M0,32L120,53.3C240,75,480,117,720,117.3C960,117,1200,75,1320,53.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
      </WrapperFooter>
  );
};

export default footer;
