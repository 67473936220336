import styled, { css } from "styled-components";
import { ICalendarState } from "./ICalendarState";
import { Calendar } from "react-date-range";

const ControlColor = (name_event: ICalendarState['name_event']) => {
    const base = "rgb(114, 121, 129);";

    switch (name_event) {
        case "normal":                  // Gray
            return base;
        case "error":                   // Red
            return "rgb(227, 28, 61);";
        case "success":                 // Green
            return "rgb(1, 165, 80);";
        case "focus":                   // Blue
            return "rgb(0, 100, 250);";
        case "focusCalendar":           // Blue
            return "rgb(0, 100, 250);";
        default:
            return base;
    }
}

const ControlFont = css`
    font-size: 24px;
    font-weight: 500;
    color: rgb(0, 0, 0);
`;

const ControlWithoutBorder = css`
    border: none;
    outline: none;
    appearance: none;
`;

export const C_WRAPPER: React.FunctionComponent<any & React.HTMLProps<HTMLInputElement>> = styled.div`
    position: relative;
    margin: 20px auto;
    
    border-radius: 16px;
    height: 80px;
    width: 100%;

    margin-bottom: 27px;
    margin-top: 40px;

    border: 2px solid ${(state: any) => ControlColor(state.name_event)}
    i { color: ${(state: any) => ControlColor(state.name_event)} }
    label { color: ${(state: any) => ControlColor(state.name_event)} }
`;

export const C_INPUT = styled.input`
    ${ControlWithoutBorder}
    ${ControlFont}

    display: block;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    width: 85%;
    height: 100%;
    left: 0;
    margin-left: 10px;

    pointer-events: none;
`;

export const C_CALENDAR_WRAPPER = styled.div`
    position: absolute;
    width: 101%;
    margin: 0px 10px 0px -2px;
    border-radius: 20px;
    z-index: 9;
`;

export const C_CALENDAR = styled(Calendar)`
position: relative;
    width: 100%;
    border: 2px solid rgb(0, 100, 250);;
    border-radius: 16px;
    margin-top: 10px;
    padding-right: 10px;
    margin: 0 auto;

    padding: 10px;
    
    .rdrMonth {
        width: 95%;

        .rdrWeekDays {
            font-size: 16px;
        }
    }

    .rdrMonthAndYearWrapper{
        font-size: 16px;
    }

    .rdrDayNumber {
        font-size: 16px;
    }

    .rdrDay:not(.rdrDayDisabled):not(.rdrDayToday) .rdrDayNumber span {
        color: blue !important;
    }
`;

export const C_ICON_WRAPPER = styled.div`
    position: absolute;
    right: 0;
    margin-top: -50px;
    margin-right: 25px;

    text-align: center;
    width: 30px;
    height: 30px;

    pointer-events: none;
`;
    
export const C_ICON = styled.i`
    position: relative;
    font-size: 25px;
`;

export const C_LABEL: React.FunctionComponent<any & React.HTMLProps<HTMLDivElement>> = styled.label`
    ${ControlFont}

    pointer-events: none;
    background: #FFF;
    display: block;
    position: absolute;
    left: 0;
    margin-left: 9px;
    padding: 2px;
    margin-top: -56px;
    transition: 0.2s ease all;
    transform: ${(props: any) => props.value || props.name_event === "focus" ? "translateY(-130%)" : "translateY(0%)"};
`;

export const C_LABEL_ERROR: React.FunctionComponent<any & React.HTMLProps<HTMLDivElement>> = styled.label`
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);

    pointer-events: none;
    display: block;
    position: absolute;
    margin-left: 12px;
    transition: 0.2s ease all;
    margin-top: 5px;
`; 