import styled from "styled-components";

export const IconOK = styled.i`
  color: rgb(1, 165, 80);
  font-size: 80px;
`;

export const TextOK = styled.h1`
  color: rgb(1, 165, 80);
  font-size: 48px;
  font-weight: bold;
`;

export const Subtitle = styled.h2`
  color: rgb(0, 0, 0);
  font-size: 35px;
  font-weight: bold;
`;

export const TextInfo = styled.h3`
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
`;

export const IconLeft = styled.i`
  margin-right: 10px;
`;

export const DisplayInfo = styled.h3`
  display: flex;
  align-items: center;

  @media (min-width: 320px) {
    margin-left: 10%;
  }

  @media (min-width: 550px) {
    margin-left: 15%;
  }

  @media (min-width: 1000px) {
    margin-left: 25%;
  }

  @media (min-width: 1440px) {
    margin-left: 25%;
  }
`;
