import React, { useState } from 'react';
import { IButtonProps } from './IButtonProps';
import { IButtonState } from "./IButtonState";
import { CUSTOM_BUTTON } from './Button.styles';

const Button: React.SFC<IButtonProps & React.HTMLProps<HTMLButtonElement>> = (props: IButtonProps) => {
  const [state] = useState<IButtonState>({ name_event: "normal" });

  useState()
  return (
    <CUSTOM_BUTTON
      {...(state as IButtonState)}
      disabled={props.disabled}
    >
      {props.children}
    </CUSTOM_BUTTON>
  );
};

export default Button;
