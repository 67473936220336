import * as React from 'react';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { IVirtualAssistanceProps } from './IVirtualAssistanceProps';
import { C_FORM, SubTitle } from './VirtualAssistance.styles';

// Components
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Calendar from '../../components/Calendar/Calendar';
import Button from "../../components/Button/Button";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import moment, { now } from 'moment-business-days';
import { DateRangeProps } from "react-date-range";

// @ts-ignore
import * as locales from "react-date-range/dist/locale";
import { getSearchHoursAvailable } from '../../service/landing';

const BASE_HOURS_WORKING = {
  "09:00": "09:00",
  "10:00": "10:00",
  "11:00": "11:00",
  "12:00": "12:00",
  "13:00": "13:00",
  "14:00": "14:00",
  "15:00": "15:00",
  "16:00": "16:00",
  "17:00": "17:00"
}

enum ENUM_ADVISORY {
  Ahorro = "Ahorro",
  Previsional = "Previsional",
  Servicios = "Servicios"
}

enum ENUM_CONTACT {
  MensajeDeTexto = "Mensaje de texto"
}

const schema = yup.object().shape({
  // eslint-disable-next-line
  date: yup.string().required('La Fecha es necesaria').matches(new RegExp(/^(?=\d{2}([-.,\/])\d{2}\1\d{4}$)(?:0[1-9]|1\d|[2][0-8]|29(?!.02.(?!(?!(?:[02468][1-35-79]|[13579][0-13-57-9])00)\d{2}(?:[02468][048]|[13579][26])))|30(?!.02)|31(?=.(?:0[13578]|10|12))).(?:0[1-9]|1[012]).\d{4}$/), { message: "El formato de la fecha es DD/MM/YYYY" }),
  hour: yup.mixed<string>().oneOf(Object.values(BASE_HOURS_WORKING)).required('La hora es necesaria'),
  name: yup.string().required("El nombre es necesario").matches(/^[aA-zZ\s]+$/, "Solo se puede ingresar letras"),
  email: yup.string().required("El correo es necesario").email('Ingresa un correo válido'),
  phone: yup.string().required("El teléfono es necesario").matches(new RegExp(/^[0-9*#+]+$/), { message: "Debes ingresar un numero válido" }).min(9, "El minimo de digitos son 9").max(12, "El maximo de digito son 12"),
  advisory: yup.mixed<ENUM_ADVISORY>().oneOf(Object.values(ENUM_ADVISORY)).required('La asesoría es necesaria'),
  contact: yup.mixed<ENUM_CONTACT>().oneOf(Object.values(ENUM_CONTACT)).required('El medio de contacto es necesario'),
});

const VirtualAssistance: React.SFC<IVirtualAssistanceProps> = (props) => {

  const [options, setOptions] = React.useState(undefined);

  const { control, setValue, errors, formState, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      date: "",
      hour: "",
      name: "",
      email: "",
      phone: "",
      advisory: "",
      contact: ""
    }

  });

  moment.updateLocale('es_CL', {
    workingWeekdays: [1, 2, 3, 4, 5]
  })

  /**
   * @implements  Start Calendar
   */

  const nowDate = moment().toDate();
  const minDate = moment().isBusinessDay() ? moment().toDate() : moment().nextBusinessDay().toDate();
  const maxDate = moment(minDate).businessAdd(2).toDate();

  const disableDaysNotBusiness = (startDate: any, endDate: any) => {

    const disableDays = [];

    const initialDate = moment(startDate).startOf('day');
    const finalDate = moment(endDate).startOf('day');

    while (initialDate.add(1, 'days').diff(finalDate) < 0) {
      if (!moment(initialDate).isBusinessDay())
        disableDays.push(initialDate.toDate());
    }

    return disableDays;
  };

  const propsRange: DateRangeProps = {
    minDate,
    maxDate,
    locale: locales['es'],
    showMonthArrow: false,
    showPreview: false,
    showDateDisplay: false,
    disabledDates: disableDaysNotBusiness(minDate, maxDate)
  }

  const validateDaysWrittenManual = (dateToFind: string) => {
    let isValid = false;
    const listDays = [];

    const diff = moment(maxDate).diff(minDate, 'days');
    for (let i = 0; i <= diff; i++) {
      listDays.push(moment(minDate).add(i, 'days').format('DD/MM/YYYY'));
    }

    const listDaysDisabled = disableDaysNotBusiness(minDate, maxDate).map(date => moment(date).format('DD/MM/YYYY'));
    const listDaysCleaned = listDays.filter(date => !listDaysDisabled.includes(date));

    if (listDaysCleaned.includes(dateToFind)) {
      isValid = true;
    }

    return isValid;
  }

  const isPastWorkingHours = (lastWorkingHour: String, selectedHour: String): boolean => {
    const lastWorkingHourNum: number = Number(lastWorkingHour.substring(0, 2));
    const selectedHourNum: number = Number(selectedHour.substring(0, 2));
    return lastWorkingHourNum >= selectedHourNum;
  }

  const loadHoursAvailable = (date: string) => {
    getSearchHoursAvailable(date).then(res => {
      let opt: any = {};

      const executives_len = res.data[res.data.length - 1].executive_len;
      
      const hoursNotAvailable: Array<string> = res.data.map((item: any) => moment.utc(item.scheduled_at).local().format("HH:mm"));
      hoursNotAvailable.splice(res.data.length - 1)
      
      const nowHour = moment().local().format("HH:mm");
      const nowDay = moment().local();

      const dateAsMoment = moment(date, 'DD/MM/YYYY').isSame(moment(nowDay), 'date');
      const isFriday = moment(date, 'DD/MM/YYYY').isoWeekday() === 5;

      for (const key in BASE_HOURS_WORKING) {
        const countHours = hoursNotAvailable.filter((x: any) => x === key).length;
        const ishourAfterNow = moment(nowHour, "HH:mm").isAfter(moment(key, "HH:mm")) && dateAsMoment;
        const isFridayAndBeforeWorkingHours = isFriday && !isPastWorkingHours("14:00", key);

        if (countHours < executives_len && !ishourAfterNow && !isFridayAndBeforeWorkingHours) {
          opt[key] = key;
        }
      }

      setOptions(opt)
    });
  }
  /**
   * @implements End Calendar
   */

  return (
    <C_FORM onSubmit={handleSubmit(props.onSubmit())}>

      <SubTitle>Agenda tu asesoría por Videollamada</SubTitle>

      <Controller
        control={control}
        name={"date"}
        render={({ value, onBlur }) => (
          <Calendar
            onSetValue={(value: any) => {
              setValue("date", value, { shouldValidate: true });
              loadHoursAvailable(value);
            }}
            value={value}
            date={nowDate}
            error={errors.date?.message}
            label="Selecciona una fecha"
            icon="lp-date"
            iconOK="lp-check"
            iconERROR="lp-error"
            dateRangeProps={propsRange}
            onBlur={onBlur}
            onChange={(event: any) => {
              const value = event.target.value;
              if (value.length === 10 && validateDaysWrittenManual(value) === false) {
                setError("date", { message: "Esta fecha no esta habilitada" });
              } else {
                setValue("date", value, { shouldValidate: true });
                loadHoursAvailable(value);
              }
            }}
          />
        )}
      />

      {options && (
        <Controller
          control={control}
          name="hour"
          defaultValue=""
          render={({ value, onBlur }) => (
            <Select
              value={value}
              options={options}
              error={errors.hour?.message}
              label="Selecciona una hora"
              icon="lp-time"
              iconOK="lp-check"
              iconERROR="lp-error"
              onBlur={onBlur}
              onChange={(event: any) => {
                setValue("hour", event.target.value, { shouldValidate: true });
              }}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="name"
        defaultValue=""
        render={({ value, onBlur }) => (
          <Input
            value={value}
            error={errors.name?.message}
            label="Ingresa tu nombre"
            icon="lp-name"
            iconOK="lp-check"
            iconERROR="lp-error"
            onBlur={onBlur}
            onChange={(event: any) => {
              setValue("name", event.target.value, { shouldValidate: true });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        defaultValue=""
        render={({ value, onBlur }) => (
          <Input
            value={value}
            error={errors.email?.message}
            label="Ingresa tu correo"
            icon="lp-email"
            iconOK="lp-check"
            iconERROR="lp-error"
            onBlur={onBlur}
            onChange={(event: any) => {
              setValue("email", event.target.value, { shouldValidate: true });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="phone"
        defaultValue=""
        render={({ value, onBlur }) => (
          <Input
            value={value}
            error={errors.phone?.message}
            label="Ingresa tu teléfono"
            icon="lp-phone"
            iconOK="lp-check"
            iconERROR="lp-error"
            onBlur={onBlur}
            onChange={(event: any) => {
              setValue("phone", event.target.value, { shouldValidate: true });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="advisory"
        render={({ value, onBlur }) => (
          <Select
            value={value}
            options={ENUM_ADVISORY}
            error={errors.advisory?.message}
            label="Tipo de asesoría"
            icon="lp-dropdown"
            iconOK="lp-check"
            iconERROR="lp-error"
            onBlur={onBlur}
            onChange={(event: any) => {
              setValue("advisory", event.target.value, { shouldValidate: true });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="contact"
        render={({ value, onBlur }) => (
          <Select
            value={value}
            options={ENUM_CONTACT}
            error={errors.contact?.message}
            label="Medio de contacto"
            icon="lp-dropdown"
            iconOK="lp-check"
            iconERROR="lp-error"
            onBlur={onBlur}
            onChange={(event: any) => {
              setValue("contact", event.target.value, { shouldValidate: true });
            }}
          />
        )}
      />
      <Button disabled={!formState.isValid}>Agendar Videollamada</Button>
    </C_FORM>
  );
};

export default VirtualAssistance;
