import styled from "styled-components";

export const C_FORM = styled.form``;

export const SubTitle = styled.label`
    color: rgb(0, 100, 250);
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    line-height: 64px;
`;