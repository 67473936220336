import styled, { css } from "styled-components";
import { ISelectState } from "./ISelectState";

const ControlColor = (name_event: ISelectState['name_event']) => {
    const base = "rgb(114, 121, 129);";

    switch (name_event) {
        case "normal":      // Gray
            return base;
        case "error":       // Red
            return "rgb(227, 28, 61);";
        case "success":     // Green
            return "rgb(1, 165, 80);";
        case "focus":       // Blue
            return "rgb(0, 100, 250);";
        default:
            return base;
    }
}

const ControlFont = css`
    font-size: 24px;
    font-weight: 500;
    color: rgb(0, 0, 0);
`;

const ControlWithoutBorder = css`
    border: none;
    outline: none;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
`;

export const C_WRAPPER: React.FunctionComponent<any & React.HTMLProps<HTMLInputElement>> = styled.div`
    position: relative;
    margin: 20px auto;
    
    border-radius: 16px;
    height: 80px;
    width: 100%;

    margin-bottom: 27px;
    margin-top: 40px;

    border: 2px solid ${(state: any) => ControlColor(state.name_event)}
    i { color: ${(state: any) => ControlColor(state.name_event)} }
    label { color: ${(state: any) => ControlColor(state.name_event)} }
`;

export const C_SELECT = styled.select`
    ${ControlWithoutBorder}
    ${ControlFont}

    background-color: transparent;
    display: block;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    width: 100%;
    height: 100%;
    padding-left: 6px;
    left: 0;

    opacity: ${(props: any) => props.value || props.name_event === "focus" ? "1" : "0"};
`;

export const C_OPTION = styled.option``;

export const C_ICON_WRAPPER = styled.div`
    position: absolute;
    right: 0;
    margin-top: -50px;
    margin-right: 25px;

    text-align: center;
    width: 30px;
    height: 30px;

    pointer-events: none;
`;
    
export const C_ICON = styled.i`
    position: relative;
    font-size: 25px;
`;

export const C_LABEL: React.FunctionComponent<any & React.HTMLProps<HTMLDivElement>> = styled.label`
    ${ControlFont}

    pointer-events: none;

    background: #FFF;
    display: block;
    position: absolute;
    left: 0;
    margin-left: 9px;
    padding: 2px;
    margin-top: -56px;
    transition: 0.2s ease all;
    transform: ${(props: any) => props.value || props.name_event === "focus" ? "translateY(-130%)" : "translateY(0%)"};
`;

export const C_LABEL_ERROR: React.FunctionComponent<any & React.HTMLProps<HTMLDivElement>> = styled.label`
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);

    pointer-events: none;
    display: block;
    position: absolute;
    margin-left: 12px;
    transition: 0.2s ease all;
    margin-top: 5px;
`; 