import { IButtonState } from "./IButtonState";
import styled, { css } from "styled-components";

const ControlWithoutBorder = css`
    border: none;
    outline: none;
    appearance: none;
`;

const ControlColor = (name_event: IButtonState['name_event']) => {
  const base = "rgb(0, 100, 250)";

  switch (name_event) {
      case "normal":    // Blue
          return base;
      default:
          return base;
  }
}

export const CUSTOM_BUTTON: React.FunctionComponent<IButtonState | React.HTMLProps<HTMLButtonElement> > = styled.button`
  ${ControlWithoutBorder}
  background: ${(state: IButtonState) => ControlColor(state.name_event)};
  border-radius: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 100, 250, 0.3);
  color: rgb(255, 255, 255);

  width: 100%;
  padding: 15px 0px 15px 0px;

  transition: transform 150ms ease-out 0s;
  &:active {
    transform: scale(0.9);
  }

  :disabled {
    background: rgb(153, 162, 172);
  }

  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;

`;