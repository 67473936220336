import React from 'react';

import Body from "../body/body";
import Footer from "../footer/footer";

const App = () => {
	return (
		<React.Fragment>
			<Body />

			<Footer />
		</React.Fragment>
	);
}

export default App;