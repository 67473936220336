import * as React from 'react';
import { IFormOKProps } from './IFormOKProps';
import { IconOK, TextOK, Subtitle, TextInfo, DisplayInfo, IconLeft } from './FormOK.styled';
import moment from 'moment';

const FormOK: React.FC<IFormOKProps> = (props) => {
  const { schedule }: any = props;
  const { lead_data }: any = schedule;
  const date = moment(schedule.scheduled_at).format('DD-MM-YYYY');
  const hour = moment(schedule.scheduled_at).format('HH:mm');

  return (
    <React.Fragment>
        <IconOK className="lp-check_circle" />

        <TextOK>La videollamada ha sido agendada exitosamente!</TextOK>

        <Subtitle>Estos son los datos de la reunión</Subtitle>

        <div>
            <DisplayInfo><IconLeft className="lp-date" />Fecha: {date}</DisplayInfo>
            <DisplayInfo><IconLeft className="lp-time" />Hora: {hour}</DisplayInfo>
            <DisplayInfo><IconLeft className="lp-advisory" />Tipo de asesoría: {lead_data.advisory}</DisplayInfo>
            <DisplayInfo><IconLeft className="lp-mobil-phone" />Medio de contacto: {lead_data.contact}</DisplayInfo>
        </div>

        <Subtitle>Próximos pasos</Subtitle>

        <TextInfo>Te enviaremos un email con los datos de la reunión</TextInfo>

        <TextInfo>El link de la reunión será enviado el día y hora agendado, por el medio que escogiste para ser contactado.</TextInfo>
    </React.Fragment>
  );
};

export default FormOK;
