import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOOLY_API_URL_AUX || "https://api.dev.hooly.app";
const BASE_PATHNAME = "scheduling";

interface ISaveSchedule {
    date: string,
    hour: string,
    name: string,
    email: string,
    phone: string,
    advisory: string,
    contact: string,
    scheduled_at: string
};

export const saveSchedule = (data: ISaveSchedule) => {
    return axios.post(`${BASE_URL}/${BASE_PATHNAME}/videocall`, {
        scheduled_at: data.scheduled_at,
        date: data.date,
        hour: data.hour,
        lead_data: {
            first_name: data.name,
            email: data.email,
            phone_number: data.phone,
            advisory: data.advisory,
            contact: data.contact
        }
    });
}

export const getSearchHoursAvailable = (date: string) => {
    return axios.get(`${BASE_URL}/${BASE_PATHNAME}/videocall/search_available_hours_for_date`, {
        params: {
            date
        }
    });
}