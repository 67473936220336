import * as React from 'react';
import {  ICalendarState } from './ICalendarState';
import {  ICalendarProps } from './ICalendarProps';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {  C_WRAPPER, C_CALENDAR, C_CALENDAR_WRAPPER, C_ICON_WRAPPER, C_ICON, C_LABEL, C_LABEL_ERROR } from './Calendar.styles';

import moment from "moment";

import { C_INPUT } from '../Input/Input.styles';
import { DateRangeProps } from 'react-date-range';

class Calendar extends React.Component<ICalendarProps & React.HTMLProps<HTMLInputElement>, ICalendarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name_event: "normal",
      value: "",
      date: this.props.date
    };
  }

  componentDidUpdate(previousProps: any, previousState: any) {
    if (previousProps.value !== this.props.value || previousProps.error !== this.props.error) {
      this.setState({
        name_event: this.state.value && !this.props.error ? "success" : "error"
      });
    }
  }

  onFocus = () => { this.setState({ name_event: "focus" }); }
  
  onBlur = () => {
    let nameEventOnblue: ICalendarState["name_event"];

    switch (this.state.name_event) {
      case "error":
        nameEventOnblue = 'error';
        break;
      case "success":
        nameEventOnblue = 'success';
        break;
      case "focus":
        if (this.props.error)
          nameEventOnblue = "error";
        else if (this.state.value)
          nameEventOnblue = "success";
        else
          nameEventOnblue = "normal";
        break;
      case "focusCalendar":
        nameEventOnblue = "focusCalendar"
        break;
      default:
        nameEventOnblue = 'normal';
        break;
    }

    this.setState({ name_event: nameEventOnblue });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    this.setState({ value: event?.target.value });
  };

  public render(): JSX.Element {
    const { value: valueIn, label, icon, iconOK, iconERROR, error, dateRangeProps } = this.props;
    const { name_event, value } = this.state;
    
    const valueFinal = value || valueIn || "";

    return (
      <C_WRAPPER name_event={name_event}>
        <C_INPUT
          onChange={(event: any) => {
            this.onChange(event);
          }}
          value={valueFinal}
          onFocus={() => this.onFocus()}
          onBlur={() => {this.onBlur()}}
          onClick={() => this.setState({ name_event: "focus" })}
        />
        <C_LABEL name_event={name_event} value={valueFinal}>{label}</C_LABEL>
        <C_ICON_WRAPPER>
          <C_ICON className={name_event === "error" ? (iconERROR ? iconERROR : icon) : (name_event === "success" && iconOK ? iconOK : icon)} />
        </C_ICON_WRAPPER>
        {name_event === "error" && (<C_LABEL_ERROR>{error}</C_LABEL_ERROR>)}

        {(name_event === "focus" || name_event === "focusCalendar") && (
          <C_CALENDAR_WRAPPER
            onMouseDownCapture={() => this.setState({ name_event: "focusCalendar" })}
          >
          <C_CALENDAR
            date={this.state.date}
            onChange={(item: any) => {
              this.setState({ name_event: "success", value: moment(item).format("DD/MM/YYYY"), date: item });
              this.props.onSetValue(moment(item).format("DD/MM/YYYY"));
            }}
            {...(dateRangeProps as DateRangeProps)}
          />
        </C_CALENDAR_WRAPPER>
        )}
        
      </C_WRAPPER>
    );
  }
}

export default Calendar;
