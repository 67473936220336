import * as React from 'react';
import { IInputState } from './IInputState';
import { IInputProps } from './IInputProps';
import { C_WRAPPER, C_INPUT, C_ICON_WRAPPER, C_ICON, C_LABEL, C_LABEL_ERROR } from './Input.styles';

class Input extends React.Component<IInputProps & React.HTMLProps<HTMLInputElement>, IInputState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name_event: "normal"
    };
  }

  componentDidUpdate(previousProps: any, previousState: any) {
    if (previousProps.value !== this.props.value || previousProps.error !== this.props.error) {
      this.setState({
        name_event: this.props.value && !this.props.error ? "success" : "error"
      });
    }
  }

  onFocus = () => { this.setState({ name_event: "focus" }); }

  onBlur = () => {
    let nameEventOnblue: IInputState["name_event"];

    switch (this.state.name_event) {
      case "error":
        nameEventOnblue = 'error';
        break;
      case "success":
        nameEventOnblue = 'success';
        break;
      case "focus":
        if (this.props.error)
          nameEventOnblue = "error";
        else if (this.props.value)
          nameEventOnblue = "success";
        else
          nameEventOnblue = "normal";
        break;
      default:
        nameEventOnblue = 'normal';
        break;
    }

    this.setState({ name_event: nameEventOnblue });
  }

  public render(): JSX.Element {
    const { label, icon, iconOK, iconERROR, value, error } = this.props;
    const { name_event } = this.state;

    return (
      <C_WRAPPER name_event={name_event}>
        <C_INPUT
          {...(this.props as any)}
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
        />
        <C_LABEL name_event={name_event} value={value}>{label}</C_LABEL>
        <C_ICON_WRAPPER>
          <C_ICON className={name_event === "error" ? (iconERROR ? iconERROR : icon) : (name_event === "success" && iconOK ? iconOK : icon)} />
        </C_ICON_WRAPPER>
        {name_event === "error" && (<C_LABEL_ERROR name_event={name_event}>{error}</C_LABEL_ERROR>)}
      </C_WRAPPER>
    );
  }
}

export default Input;
