import * as React from 'react';

import { IbodyState } from './IbodyState';
import { IbodyProps } from './IbodyProps';

import { Container, Row, Col } from "react-grid-system";

import {
  Title
} from './body.style';

import Logo from "../resources/logo-capital.svg";

import VirtualAssistanceForm from "../forms/VirtualAssistance/VirtualAssistance";
import { saveSchedule } from '../service/landing';
import FormOK from './pages/FormOK/FormOK';
import moment from 'moment';

class body extends React.Component<IbodyProps, IbodyState> {
  constructor(props: IbodyProps | Readonly<IbodyProps>) {
    super(props);
    this.state = {
      step: "FORM",
      schedule: {}
    };
  }

  onSubmit = async (data: any) => {
    const { date, hour } = data;

    const scheduledAt = moment(`${date} ${hour}`, "DD/MM/YYYY HH:mm").toISOString();
    data['scheduled_at'] = scheduledAt;

    try {
      const res = await saveSchedule(data);
      const doc = res.data;
      this.setState({ step: "FORM_OK", schedule: doc });
    } catch (err) {
      console.log(err);
    }
  }

  public render(): JSX.Element {
    const { step, schedule } = this.state;

    return (
      <Container fluid>

        <Row justify="center">
          <Col md={5} style={{ textAlign: "center" }}>

            <Title src={Logo} />

            <br />

            {step === "FORM" && (
              <VirtualAssistanceForm onSubmit={() => this.onSubmit} />
            )}

            {step === "FORM_OK" && (
              <FormOK schedule={schedule} />
            )}

          </Col>
        </Row>

      </Container>
    );
  }
}

export default body;
